<template>
  <div class="title">
    <i></i>
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.title {
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #1a1a1a;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 10px;
  @include respond-to(sm) {
    font-size: 18px;
  }
  i {
    height: 1em;
    width: 7px;
    margin-right: 15px;
    background-color: #0075c1;
    @include respond-to(sm) {
      width: 3px;
      margin-right: 10px;
    }
  }
}
</style>