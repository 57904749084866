<template>
  <div class="content">
    <div class="main">
      <service-and-support-nav :active-index="0" />
      <section class="oneView">
        <title-view class="title" title="DAkkS-校準實驗室" />
        <div class="oneBox">
          <div class="leftView">
            <img :src="one.pic" alt="" />
          </div>
          <div class="rightView">
            <div class="tabBtnView">
              <div
                :style="`width:${100 / one.laboratory_list.length}%`"
                :class="index == oneTabIndex ? 'active' : ''"
                class="tabBtnItem"
                v-for="(item, index) in one.laboratory_list"
                :key="index"
                @click="tabFun(index)"
              >
                {{ item.title }}
              </div>
            </div>
            <div class="tabBoxView">
              <div
                class="tabBtnItem"
                v-for="(item, index) in one.laboratory_list"
                :key="index"
              >
                <template v-if="index == oneTabIndex">{{
                  item.description
                }}</template>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="projectView">
        <title-view class="title" title="專業校準項目" />
        <div class="projectViewContent">
          <img class="pic" :src="project.pic" alt="" />
          <div>
            <div
              class="item"
              v-for="(item, index) of project.list"
              :key="index"
            >
              <div class="imgView">
                <img :src="item.pic" alt="" />
              </div>
              <div class="txtView">
                <strong>{{ item.title }} - </strong>
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="twoView">
        <title-view class="title" title="校準套餐" />
        <div class="imgView">
          <img
            v-for="(item, index) in two.package_json"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
        <div class="downloadView">
          <div class="download" @click="downloadFun(two.download_url)">
            <img src="@/assets/images/service/pdf.jpg" alt="" />
            <span>詳情下載</span>
          </div>
        </div>
      </section>
      <section class="threeView">
        <title-view class="title" title="IQ/OQ/PQ 報告書" />
        <div class="threeBox">
          <div class="threeCon">
            <div class="imgView">
              <img :src="three.report_pic" alt="" />
            </div>
            <div class="txtView">
              <div
                class="txtItem"
                v-for="(item, index) in three.report_list"
                :key="index"
              >
                <div class="leftView">
                  <img :src="item.pic" alt="" />
                </div>
                <div class="rightView">
                  <h4 class="NotoSansTCBold">{{ item.title }}</h4>
                  <div class="subtitle">{{ item.sub_title }}</div>
                  <div class="txt">{{ item.content }}</div>
                  <ul v-if="item.content_list.length != 0">
                    <li
                      v-for="(item_l, index_l) in item.content_list"
                      :key="index_l"
                    >
                      {{ item_l }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="remarks">
            {{ three.report_remarks }}
          </div>
        </div>
      </section>
      <section class="fourView">
        <title-view class="title" title="關於台北DAkkS 分支" />
        <!-- <div class="fourBox">
          <div class="imgView">
            <img :src="four.pic" alt="" />
          </div>
          <div class="txtView">
            <div class="content">{{ four.content }}</div>
            <div class="nameView">
              <div>{{ four.name }}</div>
              <span>{{ four.role }}</span>
            </div>
          </div>
        </div> -->
        <div class="swiperView">
          <div class="swiperBox">
            <swiper :options="swiperOptionTwo" ref="mySwiperOne">
              <swiper-slide v-for="(item, index) in four" :key="index">
                <div class="swiperItem">
                  <div class="leftView">
                    <div class="photoView">
                      <img :src="item.pic" alt="" />
                    </div>
                    <div class="txtView">{{ item.content }}</div>
                  </div>
                  <div class="rightView">
                    <p>{{ item.name }}</p>
                    <p>{{ item.role }}</p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="people-swiper-button-prev swiper-button-prev swiperBtn"
              slot="button-prev"
            >
              <i class="el-icon-arrow-left"></i>
            </div>
            <div
              class="people-swiper-button-next swiper-button-next swiperBtn"
              slot="button-next"
            >
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </section>

      <contact-us />
    </div>
  </div>
</template>

<script>
import ServiceAndSupportNav from "@/components/service-and-support/nav";
import contactUs from "@/components/contact-us";
import titleView from "@/components/title/service";
import { downloadFile } from "@/common/utils";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "CalibrationAndMaintenance",
  components: {
    ServiceAndSupportNav,
    contactUs,
    titleView,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      one: {},
      oneTabIndex: 0,
      two: {},
      three: {},
      four: [],
      project: {},

      swiperOptionTwo: {
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".people-swiper-button-next",
          prevEl: ".people-swiper-button-prev",
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    /**
     * tab 切换
     */
    tabFun(i) {
      this.oneTabIndex = i;
    },
    /**
     * 下载
     */
    downloadFun(url) {
      downloadFile(url);
    },
    getData() {
      this.$http.calibrationAndMaintenance().then((res) => {
        if (res.code == 200) {
          this.one = res.data.maintenance;
          this.two = res.data.package;
          this.three = res.data.report;
          this.four = res.data.dakks;
          this.project = res.data.project;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.swiperView {
  position: relative;

  .swiperBox {
    .swiperItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 4vw 7vw 4vw 0;
      @include respond-to(sm) {
        flex-direction: column;
        padding: 20px;
      }
      .leftView {
        display: flex;
        align-items: center;
        @include respond-to(sm) {
          flex-direction: column;
        }
        .photoView {
          width: 15vw;
          height: 15vw;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 3vw;
          @include respond-to(lg) {
            width: 200px;
            height: 200px;
          }
          img {
            display: block;
            width: 100%;
          }
        }

        .txtView {
          width: 30vw;
          font-size: 16px;
          color: #333;
          line-height: 1.8;
          @include respond-to(lg) {
            width: 400px;
            font-size: 16px;
          }
          @include respond-to(md) {
            width: 300px;
            font-size: 14px;
          }
          @include respond-to(sm) {
            margin: 20px 0;
          }
        }
      }

      .rightView {
        p {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }

  .swiperBtn {
    background: none;

    &.swiper-button-disabled {
      display: none;
    }

    i {
      font-size: 33px;
      color: #999;
    }

    &:hover {
      i {
        color: #333;
      }
    }

    &.swiper-button-prev {
      left: -50px;
    }

    &.swiper-button-next {
      right: -50px;
    }
  }
}
.main {
  width: 1400px;
  margin: 0 auto;
  @include respond-to(max1439) {
    width: 95%;
  }
  @include respond-to(sm) {
    overflow-x: hidden;
  }
  .projectView {
    padding: 0 0 60px;
    @include respond-to(max1439) {
      padding: 0 0 30px;
    }
    .projectViewContent {
      position: relative;
      @include respond-to(md) {
        padding-top: 20px;
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
      .pic {
        width: 100%;
        display: block;
        @include respond-to(md) {
          display: none;
        }
      }
      .item {
        width: 350px;
        display: flex;
        justify-content: space-between;
        position: absolute;
        @include respond-to(md) {
          position: static;
          width: 45%;
          margin-bottom: 20px;
        }
        @include respond-to(sm) {
          width: 100%;
        }
        &:nth-child(1) {
          top: 36%;
          left: 7%;
          @include respond-to(max1439) {
            left: 0;
          }
        }
        &:nth-child(2) {
          top: 45%;
          right: 11%;
          @include respond-to(max1439) {
            right: 0;
          }
        }
        &:nth-child(3) {
          top: 72%;
          left: 7%;
          @include respond-to(max1439) {
            left: 0;
          }
        }
        &:nth-child(4) {
          top: 80%;
          right: 8%;
          @include respond-to(max1439) {
            right: 0;
          }
        }
        .imgView {
          width: 86px;
          img {
            width: 100%;
            display: block;
          }
        }
        .txtView {
          font-size: 16px;
          color: #333;
          width: calc(100% - 96px);
          strong {
            color: #1a1a1a;
            font-size: 20px;
          }
        }
      }
    }
  }
  .oneView {
    padding-bottom: 60px;
    @include respond-to(lg) {
      padding-bottom: 30px;
    }
    .title {
      margin-bottom: 20px;
    }
    .oneBox {
      display: flex;
      justify-content: space-between;
      @include respond-to(sm) {
        flex-wrap: wrap;
      }
      .leftView {
        width: 490px;
        @include respond-to(lg) {
          width: 300px;
        }

        img {
          display: block;
          width: 100%;
        }
      }
      .rightView {
        width: calc(100% - 580px);
        @include respond-to(lg) {
          width: calc(100% - 360px);
        }
        @include respond-to(md) {
          width: 100%;
        }
        .tabBtnView {
          width: 100%;
          box-sizing: border-box;
          height: 50px;
          background-color: #f0f4f5;
          border-radius: 25px;
          border: solid 1px #cccccc;
          overflow: hidden;
          display: flex;
          font-size: 14px;
          color: #333;
          margin-bottom: 40px;
          @include respond-to(lg) {
            height: 40px;
            margin-bottom: 20px;
            border-radius: 20px;
            font-size: 12px;
          }
          .tabBtnItem {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            line-height: 1;
            @include respond-to(sm) {
              text-align: center;
              line-height: 1.4;
            }
            &:hover {
              color: #0075c1;
            }
            &.active {
              background-color: #0075c1;
              color: #fff;
            }
          }
        }
        .tabBoxView {
          .tabBtnItem {
            font-size: 16px;
            color: #333;
            line-height: 1.8;
            @include respond-to(lg) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .twoView {
    margin-bottom: 100px;
    @include respond-to(max1439) {
      margin-bottom: 50px;
    }
    .title {
      margin-bottom: 40px;
    }
    .imgView {
      display: flex;
      img {
        display: block;
        width: 33.3333%;
      }
    }
    .downloadView {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
      .download {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          display: block;
          width: 30px;
          margin-right: 8px;
          @include respond-to(lg) {
            width: 20px;
          }
        }
        span {
          font-size: 24px;
          color: #0075c1;
          @include respond-to(lg) {
            font-size: 16px;
          }
        }
        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .threeView {
    padding-bottom: 70px;
    .title {
      margin-bottom: 20px;
    }
    .threeBox {
      background-image: url("../assets/images/service/page_1/iop.png");
      background-repeat: no-repeat;
      background-size: 272px auto;
      background-position: right bottom;
      @include respond-to(sm) {
        background: none;
      }
      .threeCon {
        display: flex;
        justify-content: space-between;
        .imgView {
          width: 294px;
          @include respond-to(lg) {
            display: none;
          }
          img {
            display: block;
            width: 100%;
          }
        }
        .txtView {
          width: calc(100% - 294px - 58px);
          @include respond-to(lg) {
            width: 100%;
          }
          .txtItem {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 20px;
            .leftView {
              width: 96px;
              @include respond-to(sm) {
                width: 60px;
              }
              img {
                display: block;
                width: 100%;
              }
            }
            .rightView {
              width: calc(100% - 96px - 25px);
              @include respond-to(sm) {
                width: calc(100% - 70px);
              }
              color: #1a1a1a;
              h4 {
                font-size: 20px;
                line-height: 1;
                @include respond-to(sm) {
                  font-size: 16px;
                }
              }
              .subtitle {
                font-size: 16px;
                margin: 6px 0;
                @include respond-to(sm) {
                  font-size: 14px;
                }
              }
              .txt {
                font-size: 16px;
                @include respond-to(sm) {
                  font-size: 12px;
                }
              }
              ul {
                line-height: 1.8;
                font-size: 16px;
                padding-left: 1.5em;
                list-style: disc;
                @include respond-to(sm) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .remarks {
        font-size: 16px;
        color: #333;
        @include respond-to(sm) {
          font-size: 12px;
        }
      }
    }
  }
  .fourView {
    padding-bottom: 70px;
    .fourBox {
      box-sizing: border-box;
      padding: 60px 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .imgView {
        width: 290px;
        height: 290px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          display: block;
        }
      }
      .txtView {
        width: calc(100% - 290px - 56px);
        display: flex;
        align-items: center;
        .content {
          width: 434px;
          font-size: 18px;
          color: #333;
          margin-right: 100px;
        }
        .nameView {
          color: #333;
          div {
            font-size: 18px;
          }
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>